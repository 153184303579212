import { Col, Container, Form, Image, Nav, Row, Tab } from "react-bootstrap";
import FileDropzone from "../components/FileDropzone";
import DocType from "../components/DocType";
import { Link } from "react-router-dom";
import ReactJson from "react-json-view";
import VisualizedData from "../components/VisualizedData";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

function HomePage() {
  const [selectedDocType, setSelectedDocType] = useState("invoice");
  const [selectedDocTypeForKYC, setSelectedDocTypeForKYC] = useState("invoice");
  const [responseData, setResponseData] = useState({});
  const [JSONDownloadURL, setJSONDownloadURL] = useState('');
  const [isLoading, setIsLoading] = useState(false)

  const onChangeDocType = (type) => {
    setSelectedDocType(type);
    setResponseData({})
    setIsLoading(false)
  }

  const jsonViewTheme = "bright";

  console.log({ selectedDocType });

  const exportToJson = (objectData) => {
    console.log({ objectData })
      let contentType = "application/json;charset=utf-8;";
    const jsonURL = "data:" +
        contentType +
        "," +
        encodeURIComponent(JSON.stringify(objectData));

        console.log({jsonURL})

        setJSONDownloadURL(jsonURL)
    // let filename = "export.json";
    // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //   var blob = new Blob(
    //     [decodeURIComponent(encodeURI(JSON.stringify(objectData)))],
    //     { type: contentType }
    //   );
    //   navigator.msSaveOrOpenBlob(blob, filename);
    // } else {
    //   var a = document.createElement("a");
    //   a.download = filename;
    //   a.href =
    //     "data:" +
    //     contentType +
    //     "," +
    //     encodeURIComponent(JSON.stringify(objectData));
    //   a.target = "_blank";
    //   document.body.appendChild(a);
    //   a.click();
    //   document.body.removeChild(a);
    // }
  };
  const onHandleUploadFile = (file) => {   
    setResponseData({})
    setIsLoading(true)
    console.log({selectedDocTypeForKYC})
    const formData = new FormData();
    if (selectedDocTypeForKYC === "passport") {
      formData.append("image_file", file);
      axios.post("https://api.docextractor.com/mrz", formData).then((resp) => {
        console.log({ resp });
        exportToJson(resp.data)
        setResponseData(resp.data);
        setIsLoading(false)
      });
    }
    if (
      selectedDocTypeForKYC === "aadhar" ||
      selectedDocTypeForKYC === "pan" ||
      selectedDocTypeForKYC === "vote"
    ) {
      formData.append("image_file", file);
      axios
        .post("https://imupl.click2tally.in/upload-image", formData)
        .then((resp) => {
          console.log({ resp });
          exportToJson(resp.data)
          setResponseData(resp.data);
          setIsLoading(false)
        });
    }
    if (selectedDocType === "invoice") {
      formData.append("image_file", file);
      axios.post("https://idp.docextractor.com/ocr/", formData).then((resp) => {
        console.log({ resp });
        if(resp.data) {
          exportToJson(resp.data)
          setResponseData(resp.data);
          setIsLoading(false)
        } else {
          setIsLoading(false)
          toast.error('No data.')
        }
      });
    }
  };

  const onHandleSelectDocType = (e) => {
    console.log({ event: e.target.value})
    setSelectedDocTypeForKYC(e.target.value)
    setResponseData({})
    setIsLoading(false)
  }

  return (
    <div className="Main-content">
      <Container>
        <DocType setDocType={onChangeDocType} docType={selectedDocType} />
        <div className="Main-box">
          {selectedDocType === "kyc" && (
            <Row>
              <Col lg={5}>
                <FileDropzone
                setResponseData={setResponseData}
                  isPdf={false}
                  docType={selectedDocType}
                  onUploadFile={onHandleUploadFile}
                />
              </Col>
              <Col lg={7}>
              <div className="pink-box pink-box2">
              <p>File must be of only .jpg, .png type.</p>
              
              
              <Form.Select aria-label="Default select example" onChange={onHandleSelectDocType}>
                <option>Select Document Type</option>
                <option value="passport">Passport</option>
                <option value="aadhar">Aadhar</option>
                <option value="pan">Pan</option>
                <option value="voterId">Voter Id</option>
              </Form.Select>
                </div>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Nav variant="pills" className="custom-pills">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Visualized Data</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Standardized JSON</Nav.Link>
                    </Nav.Item>
                    <Link style={{pointerEvents: JSONDownloadURL ? '' : 'none'}} to={JSONDownloadURL} className="btn-border-pink" download="export.json">
                      Download as JSON File
                    </Link>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                    {isLoading && <div className="text-center">
                      <Image className="loader" src="https://cdn.dribbble.com/users/29051/screenshots/2347771/media/d07f9fd500a13f808cdeb7748a86c589.gif" /> 
                    </div>}
                      <VisualizedData responseData={responseData} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    {isLoading && <div className="text-center">
                      <Image className="loader" src="https://cdn.dribbble.com/users/29051/screenshots/2347771/media/d07f9fd500a13f808cdeb7748a86c589.gif" /> 
                    </div>}
                      <ReactJson theme={jsonViewTheme} src={responseData} />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>
          )}
          {selectedDocType === "invoice" && (
            <Row>
              <Col lg={5}>
                <FileDropzone
                setResponseData={setResponseData}
                  isPdf={true}
                  docType={selectedDocType}
                  onUploadFile={onHandleUploadFile}
                />
              </Col>
              <Col lg={7}>
              <div className="pink-box">
              <p>File must be of only .pdf, .jpg, .png type.</p>
              </div>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Nav variant="pills" className="custom-pills">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Visualized Data</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Standardized JSON</Nav.Link>
                    </Nav.Item>
                    <Link style={{pointerEvents: JSONDownloadURL ? '' : 'none'}} to={JSONDownloadURL} className="btn-border-pink" download="export.json">
                      Download as JSON File
                    </Link>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                    {isLoading && <div className="text-center">
                      <Image className="loader" src="https://cdn.dribbble.com/users/29051/screenshots/2347771/media/d07f9fd500a13f808cdeb7748a86c589.gif" /> 
                    </div>}
                      <VisualizedData responseData={responseData} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    {isLoading && <div className="text-center">
                      <Image className="loader" src="https://cdn.dribbble.com/users/29051/screenshots/2347771/media/d07f9fd500a13f808cdeb7748a86c589.gif" /> 
                    </div>}
                      <ReactJson theme={jsonViewTheme} src={responseData} />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </div>
  );
}

export default HomePage;
