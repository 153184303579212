import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Document, Page, pdfjs } from 'react-pdf';
import { Icon } from '@iconify/react';
import { Button } from 'react-bootstrap';
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import ReactDOM from 'react-dom';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FileDropzone = ({docType, onUploadFile, isPdf, setResponseData}) => {
  const [files, setFiles] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);
  const [selectedDocType, setSelectedDocType] = useState(docType);
  const [uploadTypeError, setUploadTypeError] = useState('');

  const [pageScale, setPageScale] = useState(1);


  const onDrop = useCallback((acceptedFiles) => {
    const pdf = acceptedFiles.find((file) => file.type === 'application/pdf');
    const images = acceptedFiles.filter(
      (file) => file.type === 'image/jpeg' || file.type === 'image/png'
      );
      
      if(!isPdf && pdf?.type ===  'application/pdf') {
        setUploadTypeError('Plaese upload only .jpg or .png file.')
      } else {
        setUploadTypeError('')
        setPdfFile(pdf);
        setFiles(images);
      }
  }, []);

  console.log({ files, pdfFile })

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: [isPdf ? '.pdf' : '', 'image/jpeg', 'image/png'],
  });

  const onHandleImageUpload = () => {
    if(pdfFile) {
      onUploadFile(pdfFile, selectedDocType)
    } else {
      onUploadFile(files[0], selectedDocType)
    }
  }

  const imgRef = useRef();
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });

      img.style.setProperty("transform", value);
    }
  }, []);


  const containerRef = useRef(null);

  // useEffect(() => {
  //   if(pdfFile) {
  //     const container = ReactDOM.findDOMNode(containerRef.current);
  //     container.addEventListener('wheel', handleScroll, { passive: false });
  
  //     return () => {
  //       container.removeEventListener('wheel', handleScroll);
  //     };
  //   }
  // }, [pdfFile]);

  // const handleScroll = (e) => {
  //   if (e.deltaY > 0) {
  //     // Zoom out on scroll down
  //     setPageScale((prevScale) => Math.max(0.1, prevScale - 0.1));
  //   } else {
  //     // Zoom in on scroll up
  //     setPageScale((prevScale) => prevScale + 0.1);
  //   }
  // };

  const onRemoveImage = () => {
    setFiles([])
    setResponseData({})
    setPdfFile(null)
  }

console.log({ pageScale })
  return (
    <>
    {uploadTypeError && <p className='text-danger'>{uploadTypeError}</p>}
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        
        {(files.length === 0 && !pdfFile) &&(
          <>
          <Icon icon="tabler:upload" />
           <p>Drag and drop a receipt (JPG, PNG or PDF)</p>
          </>
        )}
        {pdfFile && (         
          <div className="pdf-preview" ref={containerRef} >
            <Document file={pdfFile}>
              <Page pageNumber={1}  scale={pageScale}/>
            </Document>
          </div>
        )}

        {files.length > 0 && <div className="image-previews">
          {files.map((file) => (           
            <QuickPinchZoom onUpdate={onUpdate}>
              <img
                ref={imgRef}
                key={file.path}
                src={URL.createObjectURL(file)}
                alt={file.path}
                className="image-preview"
              />
            </QuickPinchZoom>
          ))}
          
        </div>}
      </div>
      {pdfFile &&
        <>
          <Button variant='' className='btn-border-pink' type='button' onClick={() => setPageScale((prevScale) => prevScale + 0.1)} >+</Button>
          <Button variant='' className='btn-border-pink ms-1' type='button' onClick={() => setPageScale((prevScale) => Math.max(0.1, prevScale - 0.1))}>-</Button>
        </>
      }
      {(files.length > 0 || pdfFile) && 
        <>
          <Button variant='' className='btn-border-pink ms-1' type='button' onClick={onHandleImageUpload} >Extract</Button>
          <Button variant='' className='btn-border-pink ms-1' type='button' onClick={onRemoveImage} >Remove</Button>
        </>
      }

    </>
  );
};

export default FileDropzone;
