import { Col, Form, Image, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

function VisualizedData({ responseData }) {
  const columns = [
    {
      dataField: "description",
      text: "Description",
    },
    {
      dataField: "quantity",
      text: "Quantity",
    },
    {
      dataField: "subtotal",
      text: "Sub total",
    },
    {
      dataField: "unit_price",
      text: "Unit Price",
    },
  ];
  return (
    <Form>
      <Row>
        {Object.entries(responseData).map(
          ([key, value]) =>
            key !== "items" && (
              <Form.Group
                as={Col}
                className="mb-2"
                md="4"
                controlId="validationCustom01"
              >
                <Form.Label>{key}</Form.Label>
                <Form.Control required type="text" defaultValue={value} />
              </Form.Group>
            )
        )}
        {/* {responseData?.items?.length > 0 && <p>Items</p>} */}

        {responseData?.items?.length > 0 && (
          <BootstrapTable
            keyField="id"
            data={responseData?.items}
            columns={columns}
            cellEdit={cellEditFactory({ mode: "click" })}
          />
        )}
        {/* {responseData?.items?.map((item) =>
          Object.entries(item).map(([key, value]) => (
            <Form.Group
              as={Col}
              className="mb-2"
              md="4"
              controlId="validationCustom01"
            >
              <Form.Label>{key}</Form.Label>
              <Form.Control required type="text" defaultValue={value} />
            </Form.Group>
          ))
        )} */}
      </Row>
    </Form>
  );
}

export default VisualizedData;
